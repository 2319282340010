import BaseModel from '../core/BaseModel'
//import UserModel from '@/models/UserModel'
export default class ServiceMadatoryModel extends BaseModel {

    constructor() {
        super({
                name: "service_madatory",
                tableName: 'service_madatory',
                label: "service_madatory",
                //display_key: 'name'
            },
            {
                /*stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },*/
                type_of_use_key: {
                    name: "type_of_use_key",
                    label: "Type of use",
                    type: "Computed",
                    computed: (data) => {
                        //console.log("datadata:",data)
                        data.type_of_use_key = data.type_of_use_name;
                    },
                },
                auto_add_to_cart: {
                    name: "auto_add_to_cart",
                    label: "Auto add to cart",
                    type: "Checkbox"
                },
                day_of_stay_more_than: {
                    name: "day_of_stay_more_than",
                    label: "If days of stay in more than (days)",
                    type: "InputNumber"
                },
                user_remove_it: {
                    name: "user_remove_it",
                    label: "Can user remove it?",
                    type: "Checkbox"
                },
                /*price_key_tmp: {
                    name: "price_key_tmp",
                    label: "Price",
                    type: "Computed",
                    computed: (data) => {
                        data.price_key_tmp =  data.price_key;
                    },
                },*/
            })
    }

}