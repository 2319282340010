const serviceRouter = [
    {
        path: "list-service",
        name: "list",
        component: () => import("@/views/admin/service"),
        default: true,
        meta: {
            model: "service",
            // requiresAuth: true,
        },
    },
    {
        path: "list-service/add",
        name: "add",
        component: () => import("@/views/admin/service/function"),
        default: true,
        meta: {
            model: "service",
            // requiresAuth: true,
        },
    },
    {
        path: "list-service/edit/:id",
        name: "edit",
        component: () => import("@/views/admin/service/function"),
        default: true,
        meta: {
            model: "service",
            // requiresAuth: true,
        },
    },
];
export default serviceRouter;