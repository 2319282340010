import BaseModel from '../core/BaseModel'
//import gql from 'graphql-tag'
export default class GroupUserModel extends BaseModel {

    constructor() {
        super({ 
                name: "group_user",
                tableName: 'group_user',
                label: "User list"
            },
            {
            stt: {
                name: "stt",
                label: "No.",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
                uid: {
                name: 'uid',
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },
            username : { 
                name: 'username', 
                type: "String",
                label: 'Username',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.username = '';
                },
            },
            property : { 
                name: 'property', 
                type: "String",
                class: 'center',
                label: 'Number of property',
                computed: (data) => {
                    data.property = '';
                },
            },
            updated_name : { 
                name: 'updated_name', 
                type: "String",
                label: 'Updated by',
                computed: (data) => {
                    data.updated_name = '';
                },
            },
            updated_at : { 
                name: 'updated_at', 
                type: "Datetime",
                label: 'Updated date'
            },
            update_user: {
                refs: `update_user {id fullname}`,
                display: false
            },
            group: {
                refs: `group {id name}`,
                display: false
            },
            user: {
                refs: `user {id username fullname role_admin_property{id}}`,
                display: false
            },
        },)
    }

}