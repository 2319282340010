const rentRouter = [
    {
        path: "list-rent",
        name: "list",
        component: () => import("@/views/admin/rent"),
        default: true,
        meta: {
            model: "rent",
            // requiresAuth: true,
        },
    },
    {
        path: "list-rent/add",
        name: "add",
        component: () => import("@/views/admin/rent/function"),
        default: true,
        meta: {
            model: "rent",
            // requiresAuth: true,
        },
    },
    {
        path: "list-rent/edit/:id",
        name: "edit",
        component: () => import("@/views/admin/rent/function"),
        default: true,
        meta: {
            model: "rent",
            // requiresAuth: true,
        },
    },
];
export default rentRouter;