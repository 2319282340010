import BaseModel from '../core/BaseModel'
export default class ProductModel extends BaseModel {

    constructor() {
        super({
                name: "product",
                tableName: 'product',
                label: "Proudct",
                display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                name: {
                    name: 'name',
                    type: "String",
                    label: 'Name'
                },
                detail_get: {
                    refs:  'service_id description_en description_dn active price ordinal created_at updated_at updated_by created_by deleted lang_arr { description id id_lang name product_id } ',
                    display: false
                },
            })
    }

}