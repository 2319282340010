const appoinmentsRouter = [
    {
        path: "list-appoinments",
        name: "appoinments",
        component: () => import("@/views/admin/appoinments"),
        default: true,
        meta: {
            model: "appoinment",
            // requiresAuth: true,
        },
    },
    {
        path: "list-appoinments/events/add",
        name: "addAppoinments",
        component: () => import("@/views/admin/appoinments/function"),
        default: true,
        meta: {
            model: "appoinment",
            // requiresAuth: true,
        },
    },
    {
        path: "list-appoinments/events/edit/:id",
        name: "editAppoinments",
        component: () => import("@/views/admin/appoinments/function"),
        default: true,
        meta: {
            model: "appoinment",
            // requiresAuth: true,
        },
    },
  ];
  export default appoinmentsRouter;