const document_categoryRouter = [
  {
      path: "list-document_category",
      name: "list",
      component: () => import("@/views/admin/document_category"),
      default: true,
      meta: {
          model: "document_category",
          // requiresAuth: true,
      },
  },
  {
      path: "list-document_category/add",
      name: "add",
      component: () => import("@/views/admin/document_category/function"),
      default: true,
      meta: {
          model: "document_category",
          // requiresAuth: true,
      },
  },
  {
      path: "list-document_category/edit/:id",
      name: "edit",
      component: () => import("@/views/admin/document_category/function"),
      default: true,
      meta: {
          model: "document_category",
          // requiresAuth: true,
      },
  },
];
export default document_categoryRouter;