const recommendationUseRouter = [
  {
      path: "list-recommendation_use/used/:id",
      name: "list",
      component: () => import("@/views/admin/used"),
      default: true,
      meta: {
          model: "recommendation_use",
      },
  },
];
export default recommendationUseRouter;