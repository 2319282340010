import BaseModel from '../core/BaseModel'
//import UserModel from '@/models/UserModel'
export default class ServicePropertyTypeModel extends BaseModel {

    constructor() {
        super({
                name: "service_property_type",
                tableName: 'service_property_type',
                label: "service_property_type",
                //display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                type_name_key: {
                    name: "type_name_key",
                    label: "Type of property",
                    type: "Computed",
                    computed: (data) => {
                        //console.log("datadata:",data)
                        data.type_name_key = data.type_name;
                    },

                },
                price_key_tmp: {
                    name: "price_key_tmp",
                    label: "Price",
                    type: "Computed",
                    computed: (data) => {
                        data.price_key_tmp =  data.price_key;
                    },
                    display: false
                },
                price_key_tmp1: {
                    name: "price_key_tmp1",
                    label: "Price",
                    type: "Computed",
                    class:"right ",
                    computed: (data) => {
                        data.price_key_tmp1 =  "€"+data.price_key;
                    },
                },
            })
    }

}