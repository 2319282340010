const requestOrderRouter = [
  {
      path: "list-request-order",
      name: "requestOrder",
      component: () => import("@/views/admin/request_order"),
      default: true,
      meta: {
          model: "visit_need_to_request_item",
          // requiresAuth: true,
      },
  },
  {
      path: "list-request-order/add",
      name: "addRequestOrder",
      component: () => import("@/views/admin/request_order/function"),
      default: true,
      meta: {
          model: "visit_need_to_request_item",
          // requiresAuth: true,
      },
  },
  {
      path: "list-request-order/edit/:id",
      name: "editRequestOrder",
      component: () => import("@/views/admin/request_order/function"),
      default: true,
      meta: {
          model: "visit_need_to_request_item",
          // requiresAuth: true,
      },
  },
];
export default requestOrderRouter;