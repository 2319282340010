import BaseModel from '../core/BaseModel'
//import PropertyTypeModel from '@/models/PropertyTypeModel'
export default class PropertyUserModel extends BaseModel {

    constructor() {
        super({
                name: "property_user",
                tableName: 'property',
                label: "property_user",
                display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                part_txt: {
                    name: "part_txt",
                    label: "Part",
                    type: "Computed",
                    width: 15,
                    //filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.part_txt ='' ;
                    },
                },
                name_txt: {
                    name: "name_txt",
                    label: "Name",
                    type: "Computed",
                    width: 15,
                    //filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.name_txt ='' ;
                    },
                },

            })
    }

}