import Vue from "vue";
import VueRouter from "vue-router";
const req = require.context("./model", false, /\.js$/);
const modules = req.keys().map(req);
var allModel = [];
modules.map(m => {
  allModel.push(...m.default)
})

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Front"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/pages/NotFound/index"),
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
  {
    path: "/403",
    name: "403",
    component: () => import("@/views/pages/Forbidden/index"),
  },
  {
    path: "/redirect",
    name: "redirect",
    component: () => import("@/views/pages/redirect"),
  },
  /*{
    path: '/webview/notification/:id',
    name: 'redirect',
    component: () => import('@/views/pages/Webview/Notification')
  },*/
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/views/auth/AuthLayout"),
    children: [
      {
        path: "/",
        redirect: "/auth/login",
        component: () => import("@/views/auth/Login"),
      },
      {
        path: "/auth/login",
        name: "login",
        component: () => import("@/views/auth/Login"),
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/admin/AdminLayout"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "403",
        name: "admin403",
        component: () => import("@/views/pages/Forbidden/index"),
      },
      ...allModel
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: "history",
  //base: process.env.BASE_URL,
});
export default router;