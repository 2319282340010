import BaseModel from '../core/BaseModel'
import RecommendationCategoryModel from "@/models/RecommendationCategoryModel";
import RecommendationUsedModel from "./RecommendationUsedModel";
export default class RecommendationModel extends BaseModel {

    constructor() {
        super({
                name: "recommendation",
                tableName: 'recommendation',
                label: "recommendation list",
                display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },

                avatar: {
                    name: "avatar",
                    label: "Image",
                    custom: 'image',
                    type: "Computed",
                    computed: (data, index) => {
                        data.avatar = index + 1;
                    },
                },
                name_key: {
                    name:'name_key',
                    label:'Name',
                    type: "Selection",
                    custom: 'SelectionText',
                    models: new RecommendationCategoryModel(),
                    filter: {type: 'input', match: 'contains'},
                    class:"center",
                    computed: (data) => {
                        data.name = '';
                    },

                },
                category_id: {
                    name:'category_id',
                    label:'Category',
                    type: "Selection",
                    custom: 'SelectionText',
                    models: new RecommendationCategoryModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    class:"center",
                    computed: (data) => {
                        data.category_id = '';
                    },
                },
                address: {
                    name: 'address',
                    type: "String",
                    label: 'Address',
                    filter: {type: 'input', match: 'contains'},
                },

                discount: {
                    name: 'discount',
                    type: "String",
                    label: 'Discount',
                    display : false,

                },

                discount_value: {
                    name: 'discount_value',
                    type: "Number",
                    label: 'Discount',
                    class:"right",
                },
                // RecommendationUsedModel
                recommendation_id_key: {
                    name: 'recommendation_id_key',
                    label: 'Used',
                    type: "Input",
                    custom: 'SelectionText',
                    models: new RecommendationUsedModel(),
                    filter: { type: '', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    class:"center",
                    computed: (data) => {
                        data.recommendation_id_key = '';
                    },
                },


                active: {
                    name: "active",
                    label: "Active",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 'true', label: 'Yes', style: 'color:  #000000;'},
                        {value: 'false', label: 'No',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center ",
                },


                updated_by_key: {
                    name: "updated_by_key",
                    label: "Updated by",
                    type: "Computed",
                    width: 10,
                    computed: (data) => {
                        data.updated_by_key = '';
                    },
                },
                updated_at : {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Updated date',
                    filter: { type: 'DateRange', match: 'contains'}
                },
                detail_get: {
                    refs:  'lang_arr { id_lang id name description }  obj_recommendation_category { name id } obj_updated_by { id fullname }  use_arr { id recommendation_id  uid property_id    }  use_arr_aggregate { aggregate { count } }',
                    display: false
                },
                detail_get1: {
                    refs: 'address active discount discount_value discount_from discount_to latitude longtitude description_en description_dn category_id created_at updated_at updated_by created_by deleted',
                    display: false
                },
            })
    }

}