const serviceOrderRouter = [
  {
      path: "list-service-order",
      name: "ServiceOrder",
      component: () => import("@/views/admin/service_order"),
      default: true,
      meta: {
          model: "visit_order_item",
          // requiresAuth: true,
      },
  },
  {
      path: "list-service-order/add",
      name: "addServiceOrder",
      component: () => import("@/views/admin/service_order/function"),
      default: true,
      meta: {
          model: "visit_order_item",
          // requiresAuth: true,
      },
  },
  {
      path: "list-service-order/view/:id",
      name: "editServiceOrder",
      component: () => import("@/views/admin/service_order/function"),
      default: true,
      meta: {
          model: "visit_order_item",
          // requiresAuth: true,
      },
  },
];
export default serviceOrderRouter;