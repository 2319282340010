const recommendationRouter = [
  {
      path: "list-recommendation",
      name: "list",
      component: () => import("@/views/admin/recommendation"),
      default: true,
      meta: {
          model: "recommendation",
          // requiresAuth: true,
      },
  },
  {
      path: "list-recommendation/add",
      name: "add",
      component: () => import("@/views/admin/recommendation/function"),
      default: true,
      meta: {
          model: "recommendation",
          // requiresAuth: true,
      },
  },
  {
      path: "list-recommendation/edit/:id",
      name: "edit",
      component: () => import("@/views/admin/recommendation/function"),
      default: true,
      meta: {
          model: "recommendation",
          // requiresAuth: true,
      },
  },
];
export default recommendationRouter;