const propertyRouter = [
    {
        path: "list-property",
        name: "list",
        component: () => import("@/views/admin/property"),
        default: true,
        meta: {
            model: "property",
            // requiresAuth: true,
        },
    },
    {
        path: "list-property/add",
        name: "add",
        component: () => import("@/views/admin/property/function"),
        default: true,
        meta: {
            model: "property",
            // requiresAuth: true,
        },
    },
    {
        path: "list-property/edit/:id",
        name: "edit",
        component: () => import("@/views/admin/property/function"),
        default: true,
        meta: {
            model: "property",
            // requiresAuth: true,
        },
    },
];
export default propertyRouter;