import BaseModel from '../core/BaseModel';

export default class SettingsModel extends BaseModel {

    constructor() {
        super({
            name: "setting",
            tableName: 'setting',
            label: "Settings",
        }, {
            id: {
                name: 'id',
                type: "Uuid",
                label: 'ID',
                primaryKey: true,
                display: false
            },
            name: {
                name: 'name',
                type: "String",
                label: 'Name',
                width: 15,
                filter: { type: 'input', match: 'contains' },
            },
            prefix_phone: {
                name: 'prefix_phone',
                type: "String",
                label: 'Prefix Phone',
                width: 15,
                filter: { type: 'input', match: 'contains' },
            },
            value: {
                name: 'value',
                type: "String",
                label: 'Value',
                width: 15,
                filter: { type: 'input', match: 'contains' },
            },
            updated_at: {
                name: 'updated_at',
                type: "Datetime",
                label: 'Update date',
                filter: {
                    type: 'DateRange',
                    match: 'contains'
                }
            },
            updated_by: {
                name: 'updated_by',
                type: "Uuid",
                label: 'Updated by',
            },
            deleted: {
                name: 'deleted',
                type: "Boolean",
                label: 'Deleted',
                filter: { type: 'select', options: [{ value: true, label: 'Yes' }, { value: false, label: 'No' }] },
            },
            key: {
                name: 'key',
                type: "String",
                label: 'Key',
                width: 15,
                filter: { type: 'input', match: 'contains' },
            },
            phone: {
                name: 'phone',
                type: "String",
                label: 'Phone',
                width: 15,
                filter: { type: 'input', match: 'contains' },
            },
            email: {
                name: 'email',
                type: "String",
                label: 'Email',
                width: 15,
                filter: { type: 'input', match: 'contains' },
            },
            what_app_prefix_phone: {
                name: 'what_app_prefix_phone',
                type: "String",
                label: 'WhatsApp Prefix Phone',
                width: 15,
                filter: { type: 'input', match: 'contains' },
            },
            what_app_phone: {
                name: 'what_app_phone',
                type: "String",
                label: 'WhatsApp Phone',
                width: 15,
                filter: { type: 'input', match: 'contains' },
            },
            updated_by_key: {
                name: "updated_by_key",
                label: "Updated by Key",
                type: "Computed",
                computed: (data) => {
                    data.updated_by_key = '';
                },
            },
        })
    }
}
