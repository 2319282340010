const permissionRouter = [
  {
      path: "list-permission",
      name: "permission",
      component: () => import("@/views/admin/permission"),
      default: true,
      meta: {
          model: "group",
          // requiresAuth: true,
      },
  },
  {
      path: "list-permission/add",
      name: "addPermission",
      component: () => import("@/views/admin/permission/function"),
      default: true,
      meta: {
          model: "group",
          // requiresAuth: true,
      },
  },
  {
      path: "list-permission/edit/:id",
      name: "editPermission",
      component: () => import("@/views/admin/permission/function"),
      default: true,
      meta: {
          model: "group",
          // requiresAuth: true,
      },
  },
  {
    path: "list-permission/:id/user",
    name: "userPermission",
    component: () => import("@/views/admin/permission/user/index.vue"),
    default: true,
    meta: {
        model: "group_user",
        // requiresAuth: true,
    },
},
{
    path: "list-permission/:id/user/add",
    name: "addUserPermission",
    component: () => import("@/views/admin/permission/user/function"),
    default: true,
    meta: {
        model: "group_user",
        // requiresAuth: true,
    },
},
];
export default permissionRouter;