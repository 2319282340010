import BaseModel from '../core/BaseModel'
export default class PropertyWeekModel extends BaseModel {

    constructor() {
        super({
                name: "property_week",
                tableName: 'property_week',
                label: "Property"
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                week_number: {
                    name: 'week_number',
                    type: "Number",
                    label: 'Week'
                },
                date_start: {
                    name: 'date_start',
                    type: "Date",
                    label: 'Date start'
                },
                
                date_end: {
                    name: 'date_end',
                    type: "Date",
                    label: 'Date end'
                },
            })
    }

}