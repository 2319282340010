import BaseModel from '../core/BaseModel'
//import PropertyTypeModel from '@/models/PropertyTypeModel'
export default class UserFamilyModel extends BaseModel {

    constructor() {
        super({
                name: "user_family",
                tableName: 'users',
                label: "user_family",
                display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                name: {
                    name: "name",
                    label: "Fullname",
                    type: "Computed",
                    width: 15,
                    //filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.name ='' ;
                    },
                },
                relationship: {
                    name: "relationship",
                    label: "Relationship",
                    type: "Computed",
                    width: 15,
                    //filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.relationship ='' ;
                    },
                },
                phone_txt: {
                    name: "phone_txt",
                    label: "Phone number",
                    type: "Computed",
                    width: 15,
                    //filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.phone_txt ='' ;
                    },
                },
                email: {
                    name: "email",
                    label: "Email",
                    type: "Computed",
                    width: 15,
                    //filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.email ='' ;
                    },
                },
                personal_id: {
                    name: "personal_id",
                    label: "ID/ Passport",
                    type: "Computed",
                    width: 15,
                    //filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.personal_id ='' ;
                    },
                },
                image_list: {
                    name: "image_list",
                    label: "Images",
                    custom: 'ImageList',
                    type: "Computed",
                    computed: (data) => {
                        data.image_list = '';
                    },
                    //display: false,
                },

            })
    }

}