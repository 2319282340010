const visitRouter = [
  {
      path: "list-visit",
      name: "visit",
      component: () => import("@/views/admin/visit"),
      default: true,
      meta: {
          model: "visit",
          // requiresAuth: true,
      },
  },
  {
      path: "list-visit/add",
      name: "addVisit",
      component: () => import("@/views/admin/visit/function"),
      default: true,
      meta: {
          model: "visit",
          // requiresAuth: true,
      },
  },
  {
      path: "list-visit/edit/:id",
      name: "editVisit",
      component: () => import("@/views/admin/visit/function"),
      default: true,
      meta: {
          model: "visit",
          // requiresAuth: true,
      },
  },
];
export default visitRouter;