const termRouter = [{
        path: "list-settings",
        name: "setting",
    component: () => import("@/views/admin/settings/function"),
        default: true,
        meta: {
            model: "setting",
        },
    },
    // {
    //     path: "list-setting/add",
    //     name: "add",
    //     component: () => import("@/views/admin/settings/function"),
    //     default: true,
    //     meta: {
    //         model: "rent",
    //         // requiresAuth: true,
    //     },
    // },
];
export default termRouter;