import BaseModel from '../core/BaseModel'
//import PropertyTypeModel from '@/models/PropertyTypeModel'
export default class VisitPeopleModel extends BaseModel {

    constructor() {
        super({
                name: "visit_people",
                tableName: 'visit_people',
                label: "visit_people",
                display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                detail_get: {
                    refs:  'visit_id name personal_id is_child ordinal prefix_phone phone email relationship uid type',
                    display: false
                },
            })
    }

}