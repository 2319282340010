const bookingRouter = [
  {
      path: "list-booking",
      name: "booking",
      component: () => import("@/views/admin/booking"),
      default: true,
      meta: {
          model: "booking",
          // requiresAuth: true,
      },
  },
  {
      path: "list-booking/add",
      name: "addBooking",
      component: () => import("@/views/admin/booking/function"),
      default: true,
      meta: {
          model: "booking",
          // requiresAuth: true,
      },
  },
  {
      path: "list-booking/edit/:id",
      name: "editBooking",
      component: () => import("@/views/admin/booking/function"),
      default: true,
      meta: {
          model: "booking",
          // requiresAuth: true,
      },
  },
];
export default bookingRouter;