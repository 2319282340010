import BaseModel from '../core/BaseModel'
export default class TermOfUserPolicyModel extends BaseModel {

    constructor() {
        super({
            name: "term_of_use",
            tableName: 'term_of_use',
            label: "Term of use & Policy",
            // display_key: 'fullname'
        }, {
            id: {
                name: 'id',
                type: "Uuid",
                label: 'ID',
                primaryKey: true,
                display: false
            },
            stt: {
                name: "stt",
                label: "No.",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
            },
            name: {
                name: 'name',
                type: "String",
                label: 'Name',
                width: 60,
            },
            // title: {
            //     name: 'title',
            //     type: "Text",
            //     label: 'Tiêu đề',
            //     display: false
            // },

            // content: {
            //     name: 'content',
            //     type: "Text",
            //     label: 'Nội dung',
            //     // width: 80,
            // },
           
                updated_by_key: {
                    name: "updated_by_key",
                    label: "Updated by",
                    type: "Computed",
                    computed: (data) => {
                        data.updated_by_key = '';
                    },
                },

            updated_at: {
                name: 'updated_at',
                type: "Datetime",
                label: 'Update date',
                filter: {
                    type: 'DateRange',
                    match: 'contains'
                }
            },
            detail_get: {
                refs: 'list_use_lang { id rterm_of_use_id id_lang content } obj_updated_by { id fullname }',
                display: false
            },


        })
    }

}