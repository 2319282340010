import BaseModel from '../core/BaseModel'
export default class PropertyTypeListModel extends BaseModel {

    constructor() {
        super({
                name: "property_type_list",
                tableName: 'property_type',
                label: "Service type of property",
            display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                name: {
                    name: 'name',
                    type: "String",
                    label: 'Name',
                    class:"large-col ",
                    filter: {type: 'input', match: 'contains'},
                },
                total_property: {
                    name: "total_property",
                    label: "Property",
                    type: "Computed",
                    computed: (data) => {
                        data.total_property = '';
                    },
                    class:"center txt-small-col-midle ",
                },
                active: {
                    name: "active",
                    label: "Active",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 'true', label: 'Yes', style: 'color:  #000000;'},
                        {value: 'false', label: 'No',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center xs-col",
                },
                updated_by_key: {
                    name: "updated_by_key",
                    label: "Updated by",
                    type: "Computed",
                    class:"small-col-midle ",
                    filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.updated_by_key ='' ;
                    },
                },
                updated_at : {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Updated date',
                    class:"small-col-midle ",
                    filter: { type: 'DateRange', match: 'contains'}
                },
                detail_get: {
                    refs:  'obj_updated_by { id fullname } arr_property_aggregate(where: {deleted: {_eq: false}}) { aggregate { count } } ',
                    display: false
                },
            })
    }

}