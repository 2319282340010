import BaseModel from '../core/BaseModel'
export default class RecommendationLangModel extends BaseModel {

    constructor() {
        super({
            name: "recommendation_lang",
            tableName: 'recommendation_lang',
            label: "Recommendation lang",
        }, {
            id: {
                name: 'id',
                type: "Uuid",
                label: 'ID',
                primaryKey: true,
                display: false
            },

            id_lang: {
                display: false
            },
            recommendation_id: {
                display: false
            },
            name: {
                name: 'content',
                type: "Text",
                label: 'Nội dung',
            },
            description: {
                name: 'content',
                type: "Text",
                label: 'Nội dung',
            },

        })
    }

}