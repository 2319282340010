const full_fill_serviceRouter = [
    {
        path: "list-full_fill_service",
        name: "list",
        component: () => import("@/views/admin/full_fill_service"),
        default: true,
        meta: {
            model: "full_fill_service",
            // requiresAuth: true,
        },
    },
    {
        path: "list-full_fill_service/add",
        name: "add",
        component: () => import("@/views/admin/full_fill_service/function"),
        default: true,
        meta: {
            model: "full_fill_service",
            // requiresAuth: true,
        },
    },
    {
        path: "list-full_fill_service/edit/:id",
        name: "edit",
        component: () => import("@/views/admin/full_fill_service/function"),
        default: true,
        meta: {
            model: "full_fill_service",
            // requiresAuth: true,
        },
    },
];
export default full_fill_serviceRouter;