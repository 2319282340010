const reportManagementRouter = [
  {
      path: "list-visit_report",
      name: "list",
      component: () => import("@/views/admin/report_manegement"),
      default: true,
      meta: {
          model: "visit_report",
          // requiresAuth: true,
      },
  },
  {
      path: "list-visit_report/add",
      name: "add",
      component: () => import("@/views/admin/report_manegement/function"),
      default: true,
      meta: {
          model: "visit_report",
          // requiresAuth: true,
      },
  },
  {
      path: "list-visit_report/edit/:id",
      name: "edit",
      component: () => import("@/views/admin/report_manegement/function"),
      default: true,
      meta: {
          model: "visit_report",
          // requiresAuth: true,
      },
  },
];
export default reportManagementRouter;