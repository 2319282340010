const termRouter = [{
        path: "list-term_of_use",
        name: "Term_of_use_Policy",
        component: () => import("@/views/admin/term"),
        default: true,
        meta: {
            model: "term_of_use",
            // requiresAuth: true,
        },
    },
    {
        path: "list-term_of_use/edit/:id",
        name: "edit",
        component: () => import("@/views/admin/term/function"),
        default: true,
        meta: {
            model: "term_of_use",
            // requiresAuth: true,
        },
    },
];
export default termRouter;