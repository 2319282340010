const adminRouter = [
    {
        path: "list-view_admin",
        name: "list",
        component: () => import("@/views/admin/admin"),
        default: true,
        meta: {
            model: "view_admin",
            // requiresAuth: true,
        },
    },
    {
        path: "list-view_admin/add",
        name: "add",
        component: () => import("@/views/admin/admin/function"),
        default: true,
        meta: {
            model: "view_admin",
            // requiresAuth: true,
        },
    },
    {
        path: "list-view_admin/edit/:id",
        name: "edit",
        component: () => import("@/views/admin/admin/function"),
        default: true,
        meta: {
            model: "view_admin",
            // requiresAuth: true,
        },
    },
];
export default adminRouter;