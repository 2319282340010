const property_typeRouter = [
    {
        path: "list-property_type",
        name: "list",
        component: () => import("@/views/admin/property_type"),
        default: true,
        meta: {
            model: "property_type_list",
            // requiresAuth: true,
        },
    },
    {
        path: "list-property_type/add",
        name: "add",
        component: () => import("@/views/admin/property_type/function"),
        default: true,
        meta: {
            model: "property_type_list",
            // requiresAuth: true,
        },
    },
    {
        path: "list-property_type/edit/:id",
        name: "edit",
        component: () => import("@/views/admin/property_type/function"),
        default: true,
        meta: {
            model: "property_type_list",
            // requiresAuth: true,
        },
    },
];
export default property_typeRouter;