import BaseModel from '../core/BaseModel'
export default class TermOfUserLangModel extends BaseModel {

    constructor() {
        super({
            name: "term_of_use_lang",
            tableName: 'term_of_use_lang',
            label: "Term of use lang",
        }, {
            id: {
                name: 'id',
                type: "Uuid",
                label: 'ID',
                primaryKey: true,
                display: false
            },

            id_lang: {
                display: false
            },
            rterm_of_use_id: {
                display: false
            },
            content: {
                name: 'content',
                type: "Text",
                label: 'Nội dung',
            },
            
        })
    }

}