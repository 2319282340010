const userRouter = [
    {
        path: "list-users",
        name: "list",
        component: () => import("@/views/admin/user"),
        default: true,
        meta: {
            model: "user_admin",
            // requiresAuth: true,
        },
    },
    {
        path: "list-users/add",
        name: "add",
        component: () => import("@/views/admin/user/function"),
        default: true,
        meta: {
            model: "user_admin",
            // requiresAuth: true,
        },
    },
    {
        path: "list-users/edit/:id",
        name: "edit",
        component: () => import("@/views/admin/user/function"),
        default: true,
        meta: {
            model: "user_admin",
            // requiresAuth: true,
        },
    },
];
export default userRouter;