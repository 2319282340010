import BaseModel from '../core/BaseModel'
//import PropertyTypeModel from '@/models/PropertyTypeModel'
export default class UserPropertypartModel extends BaseModel {

    constructor() {
        super({
                name: "user_property_part",
                tableName: 'property',
                label: "user_family",
                display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                detail_get: {
                    refs:  'name part(order_by: {order_booking: asc}) { id part order_booking uid}',
                    display: false
                },
            })
    }

}