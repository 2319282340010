import BaseModel from '../core/BaseModel'
//import DocumentCategoryModel from '@/models/DocumentCategoryModel'
//import PropertyModel from '@/models/PropertyModel'
export default class RentModel extends BaseModel {

    constructor() {
        super({
                name: "rent",
                tableName: 'users',
                label: "Renter",
                display_key: 'fullname'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                avatar: {
                    name: "avatar",
                    label: "Avatar",
                    custom: 'image',
                    type: "Computed",
                    computed: (data, index) => {
                        data.avatar = index + 1;
                    },
                    //display: false,
                },
                fullname: {
                    name: 'fullname',
                    type: "String",
                    label: 'Fullname',
                    class:"small-col-midle ",
                    width: 15,
                    filter: {type: 'input', match: 'contains'},
                },
                email: {
                    name: 'email',
                    type: "String",
                    label: 'Email',
                    class:"small-col-midle ",
                    width: 15,
                    filter: {type: 'input', match: 'contains'},
                },
                full_phone: {
                    name: 'full_phone',
                    type: "String",
                    label: 'Phone number',
                    class:"small-col-midle ",
                    width: 15,
                    filter: {type: 'input', match: 'contains'},
                },
                available_from_to: {
                    name: "available_from_to",
                    label: "Availalbe form - to",
                    type: "Computed",
                    class:"small-col-midle ",
                    filter: { type: 'DateRange', match: 'contains'},
                    computed: (data) => {
                        data.available_from_to = '';
                    },
                },
                detail_get: {
                    refs:  'active prefix_phone phone available_from available_to  obj_updated_by { id fullname } ',
                    display: false
                },
                type_status_user: {
                    name: "type_status_user",
                    label: "Status",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 1, label: 'Active', style: 'color:  #000000;'},
                        {value: 2, label: 'Unactive',style: 'color:  #000000;'},
                        {value: 3, label: 'Delete',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center txt-xs-col",
                },
                /*active: {
                    name: "active",
                    label: "Active",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 'true', label: 'Yes', style: 'color:  #000000;'},
                        {value: 'false', label: 'No',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center ",
                },*/
                /*
                doc_category_id: {
                    name:'doc_category_id',
                    label:'Document category',
                    type: "Selection",
                    custom: 'SelectionText',
                    models: new DocumentCategoryModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    class:"center",
                    computed: (data) => {
                        data.doc_category_id = '';
                    },
                },
                property_id: {
                    name:'property_id',
                    label:'Property',
                    type: "Selection",
                    custom: 'SelectionText',
                    models: new PropertyModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    class:"center",
                    computed: (data) => {
                        data.doc_category_id = '';
                    },
                },*/
//obj_updated_by { id fullname }
                /*detail_get: {
                    refs:  ' ',
                    display: false
                },*/
                /*ordinal: {
                    name: 'ordinal',
                    type: "String",
                    label: 'Order'
                },*/
                /*active: {
                    name: "active",
                    label: "Active",
                    type: "Checkbox"
                },*/

                /*active: {
                    name: "active",
                   label: "Active",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 'true', label: 'Yes', style: 'color:  #000000;'},
                        {value: 'false', label: 'No',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center ",
                },

                show_only_owner: {
                    name: "show_only_owner",
                    label: "For owner",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 'true', label: 'Yes', style: 'color:  #000000;'},
                        {value: 'false', label: 'No',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center ",
                },

                 */
                updated_by_key: {
                    name: "updated_by_key",
                    label: "Updated by",
                    type: "Computed",
                    class:"small-col-midle ",
                    computed: (data) => {
                        data.updated_by_key ='' ;
                    },
                },
                updated_at : {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Updated date',
                    class:"small-col-midle ",
                    /*filter: { type: 'DateRange', match: 'contains'}*/
                }
            })
    }

}