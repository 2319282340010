import BaseModel from '../core/BaseModel'
export default class PropertyPartModel extends BaseModel {

    constructor() {
        super({
                name: "property_part",
                tableName: 'property_part',
                label: "Property"
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                uid: {
                    name: 'uid',
                    type: "String",
                    label: 'UID'
                },
                part: {
                    name: 'part',
                    type: "Number",
                    label: 'Part number'
                },
            })
    }

}