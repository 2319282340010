import BaseModel from '../core/BaseModel';
export default class NotificationUsersModel extends BaseModel {
    constructor() {
        super({ 
                name: "notification_users",
                tableName: 'notification_users', 
                label: "Thông báo người dùng", 
                display_key: 'name'
            },  
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
            updated_at: {
                name: 'updated_at', 
                type: "Datetime",
                label: 'Thời gian cập nhật',
                filter: { type: 'DateRange', match: 'contains'}
            }
        },)
    }

}