const availableRouter = [
  {
      path: "list-available",
      name: "available",
      component: () => import("@/views/admin/available"),
      default: true,
      meta: {
          model: "available",
          // requiresAuth: true,
      },
  },
//   {
//       path: "list-available/add",
//       name: "addAvailable",
//       component: () => import("@/views/admin/available/function"),
//       default: true,
//       meta: {
//           model: "available",
//           // requiresAuth: true,
//       },
//   },
  {
      path: "list-available/edit/:id",
      name: "editAvailable",
      component: () => import("@/views/admin/available/function"),
      default: true,
      meta: {
          model: "available",
          // requiresAuth: true,
      },
  },
];
export default availableRouter;