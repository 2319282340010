import BaseModel from '../core/BaseModel'
import PropertyTypeModel from '@/models/PropertyTypeModel'
export default class Full_fill_serviceModel extends BaseModel {

    constructor() {
        super({
                name: "full_fill_service",
                tableName: 'service',
                label: "Full fill fridge",
            display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                avatar: {
                    name: "avatar",
                    label: "Image",
                    custom: 'image',
                    type: "Computed",
                    computed: (data, index) => {
                        data.avatar = index + 1;
                    },
                    //display: false,
                },
                name: {
                    name: 'name',
                    type: "String",
                    label: 'Service name',
                    class:"small-col-midle",
                    width: 15,
                    filter: {type: 'input', match: 'contains'},
                },
                available_from : {
                    name: 'available_from',
                    type: "Datetime",
                    label: 'available_to',
                    filter: { type: 'DateRange', match: 'contains'},
                    display: false
                },
                available_to : {
                    name: 'available_to',
                    type: "Datetime",
                    label: 'Available to',
                    filter: { type: 'DateRange', match: 'contains'},
                    display: false
                },
                type_of_price: {
                    name: "type_of_price",
                    label: "Type of price",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: '1', label: 'Price per days', style: 'color:  #000000;'},
                        {value: '2', label: 'Price per service',style: 'color:  #000000;'},
                        {value: '3', label: 'Need to confirm',style: 'color:  #000000;'},
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center ",
                    display: false
                },
                service_type_property_key: {
                    name:'service_type_property_key',
                    label:"Service type of property",
                    type: "Selection",
                    custom: 'SelectionText',
                    models: new PropertyTypeModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    class:"center txt-small-col-midle",
                    computed: (data) => {
                        data.service_type_property_key = '';
                    },
                },
                total_product: {
                    name: "total_product",
                    label: "No. product",
                    type: "Computed",
                    width: 15,
                    class:"center xs-col",
                    //filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.total_product ='' ;
                    },
                },
                active: {
                    name: "active",
                    label: "Active",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 'true', label: 'Yes', style: 'color:  #000000;'},
                        {value: 'false', label: 'No',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center xs-col",
                },
                updated_by_key: {
                    name: "updated_by_key",
                    label: "Updated by",
                    type: "Computed",
                    filter: {type: 'input', match: 'contains'},
                    class:"small-col-midle ",
                    computed: (data) => {
                        data.updated_by_key ='' ;
                    },
                },
                updated_at : {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Updated date',
                    class:"small-col-midle ",
                    filter: { type: 'DateRange', match: 'contains'}
                },
                detail_get: {
                     refs:  'before_arrival_date obj_updated_by { id fullname }  lang_arr {id id_lang description id_service name} arr_product_aggregate(where: {deleted: {_eq: false}}) { aggregate { count } }  ',//mandatory_arr{id service_id type_of_user_id auto_add_to_cart day_of_stay_more_than user_remove_it type_user}
                    display: false
                },
                detail_get1: {
                    refs:  'active need_assign time_to_finish sold_out ordinal type_of_property_arr{id service_id type_id price obj_property_type{ name} }   ',
                    display: false
                },
            })
    }

}