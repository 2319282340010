const documentRouter = [
  {
      path: "list-document",
      name: "list",
      component: () => import("@/views/admin/document"),
      default: true,
      meta: {
          model: "document",
          // requiresAuth: true,
      },
  },
  {
      path: "list-document/add",
      name: "add",
      component: () => import("@/views/admin/document/function"),
      default: true,
      meta: {
          model: "document",
          // requiresAuth: true,
      },
  },
  {
      path: "list-document/edit/:id",
      name: "edit",
      component: () => import("@/views/admin/document/function"),
      default: true,
      meta: {
          model: "document",
          // requiresAuth: true,
      },
  },
];
export default documentRouter;