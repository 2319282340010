const notificationRouter = [
    {
        path: "list-notification",
        name: "list",
        component: () => import("@/views/admin/notification"),
        default: true,
        meta: {
            model: "notification",
            // requiresAuth: true,
        },
    },
    {
        path: "list-notification/add",
        name: "add",
        component: () => import("@/views/admin/notification/function"),
        default: true,
        meta: {
            model: "notification",
            // requiresAuth: true,
        },
    },
    {
        path: "list-notification/edit/:id",
        name: "edit",
        component: () => import("@/views/admin/notification/function"),
        default: true,
        meta: {
            model: "notification",
            // requiresAuth: true,
        },
    },
];
export default notificationRouter;