import BaseModel from '../core/BaseModel'
//import PropertyTypeModel from '@/models/PropertyTypeModel'
export default class RoleAdminPropertyModel extends BaseModel {

    constructor() {
        super({
                name: "role_admin_property",
                tableName: 'role_admin_property',
                label: "role_admin_property",
                display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                name: {
                    name: "name",
                    label: "Property",
                    type: "Computed",
                    width: 15,
                    //filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.name ='' ;
                    },
                },
            })
    }

}