//import axios from 'axios'
import {createProvider} from '../vue-apollo'
//import Vue from 'vue';
//import gql from "graphql-tag";
import DataServices from "@/core/DataServices";
//import Config from "@/config";
import Common from './Common';
import GetConstants from './Constants';

var Constants = GetConstants.getConstans();
import ApiRepository from "@/core/ApiRepository";
import AuthenticationApp from "@/core/AuthenticationApp";
import store from '@/store'
import gql from "graphql-tag";
import moment from "moment-timezone";
import Cryptojs from 'crypto-js'

export default {
    async getImage(type, obj_id, key_list) {
        return type + obj_id + key_list;
    },
    async getFile(type, obj_id, key_list) {
        var table = "file";
        var gqLQueryList = DataServices.getList(table);
        var where = {
            relationship_files: {
                obj_id: {_eq: obj_id.toString()},
                type: {_eq: type}
            },
        }
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where
            }
        });
        var listData = resData.data[table];
        return {listData: listData, key_list: key_list};
    },
    async getFileMapFile(type = [], obj_id) {
        console.log('obj_id',obj_id)
        var table = "relationship_file";
        //var tpm_obj_id = obj_id.toString()
        var gqLQueryList = DataServices.getList(table,{"fields" : `id type obj_file { id url } obj_id`});
        var where = {
            obj_id: { _in: obj_id },
            type: { _in: type }
        }
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
                orderBy:{created_at: 'asc'}
            }
        });
        var listData = resData.data[table];
        var list_map_file = {};
        var list_convert = [];
        for (let i = 0; i < listData.length; i++) {
            var itemData = listData[i];
            itemData.url =  itemData?.obj_file.url ?? null;
            var obj_id1 = itemData?.obj_id ?? null;
            if (Common.isEmpty(list_map_file[obj_id1])) {
                list_map_file[obj_id1] = []
            }
            list_map_file[obj_id1].push(itemData);
            list_convert.push(itemData)
        }
        return {listData: list_convert, list_map_file: list_map_file};
    },
    async getMultipleFile(type = [], obj_id, key_list) {
        var table = "file";
        var list_type = []
        for (let i = 0; i < type.length; i++) {
            const e = type[i];
            list_type.push({type:{_eq :e}})
        }
        var tpm_obj_id = obj_id.toString()
        var gqLQueryList = DataServices.getList(table,{"fields" : `id url file_name relationship_files(where: {obj_id: {_eq: "`+tpm_obj_id+`"}}){type obj_id}`});
        var where = {
            relationship_files: {
                obj_id: {_eq: obj_id.toString()},
                _or : list_type
            },
        }
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
                orderBy:{created_at: 'asc'}
            }
        });
        var listData = resData.data[table];
        return {listData: listData, key_list: key_list};
    },
    /*async uploadImages(folder,list_image) {
        var s3client = Config.s3Client(folder)
        var objects = [];
        console.log("list_image:",list_image);
        if (list_image) {
            //this.$store.commit('setLoading', true);
            for (let index = 0; index < list_image.length; index++) {
                const img = list_image[index];
                let file = img.source
                if (file !== '' && img.file !== '') {
                    let fileName = img.name;
                    let random = Math.random().toString().slice(2);
                    fileName = `${random}_${fileName}`
                    await s3client.uploadFile(file, fileName).then((data) => {
                        objects.push(data.location);
                    });
                }
            }
        }
        console.log("objectsobjects:",objects)
        return objects;
    },*/
    async uploadImages(folder, list_image) {
        var objects = [];
        console.log("list_image:", list_image);
        console.log("store.getters.token:", store.getters.token);
        if (list_image) {
            //this.$store.commit('setLoading', true);
            for (let index = 0; index < list_image.length; index++) {
                if (index % 5 == 0) {
                    //nếu 5 tấm sẽ nghỉ 100
                    await Common.sleep(100);
                }
                const img = list_image[index];
                let file = img.source
                if (file !== '' && img.file !== '') {
                    let fileName = img.name;
                    let random = Math.random().toString().slice(2);
                    fileName = `${random}_${fileName}`;

                    var formData = new FormData();
                    formData.append('upload', img);
                    var headerUpload = {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            "Authorization": "Bearer " + store.getters.token,
                        }
                    };
                    //console.log("55555555555555");
                    /* var response =  await ApiRepository.post(Constants.API.UPLOAD_FILE,formData,headerUpload);
                     var url_image = response.data.data.url;
                     objects.push(url_image);*/
                    await ApiRepository.post(Constants.API.UPLOAD_FILE, formData, headerUpload).then(function (response) {
                        //var url_image = response.data.data.url;
                        var dataUpload = response.data.data;
                        objects.push(dataUpload);
                    })
                        .catch(function (error) {
                            var message = error.response.data.message;
                            console.error("There was an error!", message);
                            switch (message) {
                                case 'INVALID_TOKEN_ERR':
                                    alert(Constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN)
                                    location.reload();
                                    break;
                                default:
                                    alert(Constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN)
                                //location.reload();
                            }
                        });
                }
            }
        }
        console.log("objectsobjects:", objects)
        return objects;
    },
    async updateDataImage(type, obj_id, objectFile) {
        console.log("type========",type)
        var relationshipFileInsert = {
            'type': type,
            'obj_id': (obj_id).toString(),
            'file_id': (objectFile.id).toString(),
        };
        await createProvider().defaultClient.mutate({
            //mutation: gql(queryUsers),
            mutation: DataServices.insertData('relationship_file'),
            variables: {
                objects: relationshipFileInsert
            }
        })
            .then((res) => {
                console.log('Thành công save image:', res);
            }).catch(async error => {
                console.log('error', error);
            });
    },
    async updateDataImage_ver2(type, obj_id, url) {
        var fileInsert = {
            url : url
        }
        await createProvider().defaultClient.mutate({
            //mutation: gql(queryUsers),
            mutation: DataServices.insertData('file'),
            variables: {
                objects: fileInsert
            }
        }).then(async (res) => {
            console.log("RESSSSSS===",res)
            if (res.data[Object.keys(res.data)].affected_rows) {
                var returning = res.data[Object.keys(res.data)].returning[0];
                var file_id = returning.id.toString();
                var relationshipFileInsert = {
                    'type': type,
                    'obj_id': (obj_id).toString(),
                    'file_id': file_id,
                };
                await createProvider().defaultClient.mutate({
                    //mutation: gql(queryUsers),
                    mutation: DataServices.insertData('relationship_file'),
                    variables: {
                        objects: relationshipFileInsert
                    }
                }).then(() => {
                    console.log('Thành công save image:', res);
                }).catch(async error => {
                    console.log('error', error);
                });
            }
        }).catch(async error => {
            console.log('error', error);
        });

    },
    async deleteDataImage(type, obj_id) {
        var table = "relationship_file";
        var gqLQueryList = DataServices.deleteData(table);
        var where = {type: {_eq: type}, obj_id: {_eq: obj_id.toString()}}
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where
            }
        });
        console.log("assssssssssssssss:", type);
        return resData;
    },
    async deleteDataImageMulti(type, listImageDelete) {
        if (listImageDelete && listImageDelete.length > 0) {
            var listInUrl = [];
            for (var i = 0; i < listImageDelete.length; i++) {
                listInUrl.push(listImageDelete[i].objectURL);
            }
            var table = "file";
            var gqLQueryList = DataServices.getList(table);
            var where = {
                url: {_in: listInUrl}
            }
            var resData = await createProvider().defaultClient.mutate({
                mutation: gqLQueryList,
                variables: {
                    where_key: where
                }
            });
            var listData = resData.data[table];
            //console.log("listDatalistData:",listData);
            var id_image = [];
            if (listData && listData.length > 0) {
                for (var p = 0; p < listData.length; p++) {
                    id_image.push(listData[p].id);
                }
                var table_relationship_image = "relationship_file";
                var gqLQueryListRel = DataServices.deleteData(table_relationship_image);
                var whereRel = {type: {_eq: type}, file_id: {_in: id_image}}
                await createProvider().defaultClient.mutate({
                    mutation: gqLQueryListRel,
                    variables: {
                        where_key: whereRel
                    }
                });
            }
        }
        return true;
    },
    async deleteDataImageMultilple(type, listImageDelete) {
        if (listImageDelete && listImageDelete.length > 0) {
            var listInUrl = [];
            for (var i = 0; i < listImageDelete.length; i++) {
                listInUrl.push(listImageDelete[i].objectURL);
            }
            var table = "image";
            var gqLQueryList = DataServices.getList(table);
            var where = {
                url: {_in: listInUrl}
            }
            var resData = await createProvider().defaultClient.mutate({
                mutation: gqLQueryList,
                variables: {
                    where_key: where
                }
            });
            var listData = resData.data[table];
            //console.log("listDatalistData:",listData);
            var id_image = [];
            if (listData && listData.length > 0) {
                for (var p = 0; p < listData.length; p++) {
                    id_image.push(listData[p].id);
                }
                var table_relationship_image = "relationship_image";
                var gqLQueryListRel = DataServices.deleteData(table_relationship_image);
                var whereRel = {type: {_eq: type}, image_id: {_in: id_image}}
                await createProvider().defaultClient.mutate({
                    mutation: gqLQueryListRel,
                    variables: {
                        where_key: whereRel
                    }
                });
            }
        }
        return true;
    },
    async checkPhoneExist(phone, model) {//check tồn tại sdt
        if (phone !== undefined && phone !== "") {
            // var phone = this.phone;
            // if (this.phone[0] === "0") {
            //   phone = phone.substring(1);
            // }
            // phone = phone.replace(' ', '');
            // phone = "+84" + phone;

            var where = {
                account: {'_eq': phone},
                type: {'_eq': 'PHONE'},
                deleted: {'_eq': false}
            }
            if (model !== undefined) where = Object.assign(where, {id: {'_neq': model.id}})
            var {data} = await createProvider().defaultClient.query({
                query: DataServices.getList('accounts', {"fields": "id, account, type"}),
                variables: {
                    where_key: where
                },
                fetchPolicy: "network-only"
            })
            // console.log(data)
            return data && data[Object.keys(data)].length > 0
        }
    },
    async convertLang(str_convert) {//Dịch tiếng việt sang ngôn ngữ khác
        var data = {
            str_convert: str_convert
        };
        var dataRes = {};
        var response = await ApiRepository.post(Constants.API.TRAN_CONVERT_LANG, data);
        console.log("response", response);
        var str_convert_en = response.data.data.str_convert_en;
        dataRes.str_convert_en = str_convert_en;
        return dataRes;
    },
    async getAccountByUid(uid) {
        var table_cer = 'accounts';
        var where = {uid: {_eq: uid}};
        var gqLQueryList = await DataServices.getList(table_cer);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
            }
        });
        var listData = resData.data[table_cer];
        return listData;
    },
    async getAccountByUidFirebase(uid) {
        var table_cer = 'accounts';
        var where = {id: {_eq: uid}};
        var gqLQueryList = await DataServices.getList(table_cer);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
            }
        });
        var listData = resData.data[table_cer];
        if (listData && listData.length > 0) {
            return listData[0];
        }
        return null;
    },
    async getUsersById(uid) {
        var table_cer = 'users';
        var where = {id: {_eq: uid}};
        var gqLQueryList = await DataServices.getList(table_cer);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
            }
        });
        var listData = resData.data[table_cer];
        if (listData && listData.length > 0) {
            return listData[0];
        }
        return null;
    },
    async getService() {
        var table_service = 'service';
        var where = {active: {_eq: true}, deleted: {_eq: false}};
        var gqLQueryList = await DataServices.getList(table_service);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
            }
        });
        var listData = resData.data[table_service];
        return listData;
    },
    async getListData(table, where, offset = null, limit = null, orderBy = null) {
        var variables = {
            where_key: where,
        };
        if (offset && limit) {
            variables.limit = limit;
            variables.offset = offset;
        }
        if (orderBy) {
            variables.orderBy = orderBy;
        }
        var gqLQueryList = await DataServices.getList(table);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: variables
        });
        var listData = resData.data[table];
        return listData;
    },
    async getListDataFullData(table, where, offset = null, limit = null, orderBy = null) {
        var variables = {
            where_key: where,
        };
        if (offset && limit) {
            variables.limit = limit;
            variables.offset = offset;
        }
        if (orderBy) {
            variables.orderBy = orderBy;
        }
        var gqLQueryList = await DataServices.getList(table);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: variables
        });
        return resData;
    },
    async getObjectData(table, where, order_by = null) {
        var gqLQueryList = await DataServices.getList(table);
        var variables = {
            where_key: where,
            limit:1
        };
        if (order_by) {
            variables.orderBy = order_by;
        }
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: variables
        });
        var listData = resData.data[table];
        if (listData && listData.length > 0) {
            return listData[0];
        }
        return null;
    },
    async convertFilter(type_input, key, filters) {
        if (type_input == 'DateRange') {
            if (!Common.isEmpty(filters[key]) && !Common.isEmpty(filters[key][0]) && !Common.isEmpty(filters[key][1])) {
                return {
                    type_input: 'DateRange',
                    value: {
                        from: moment(filters[key][0]).utc().format(),
                        to: moment(filters[key][1]).utc().add(24, 'hours').format()
                    },
                };
            } else {
                return {
                    type_input: 'DateRange',
                    value: null
                };
            }
        } else {
            return {
                type_input: type_input,
                value: filters[key],
            };
        }
    },
    async getSetting(code) {
        var table = 'setting';
        var where = {code: {_eq: code}};
        var gqLQueryList = await DataServices.getList(table);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
            }
        });
        var listData = resData.data[table];
        if (listData && listData.length > 0) {
            return listData[0];
        }
        return null;
    },
    getPaymentMethod(type_payment) {
        var text = "";
        var list_type_payment = Constants.LIST_TYPE_PAYMENT;
        for (var i = 0; i < list_type_payment.length; i++) {
            if (list_type_payment[i].value == type_payment) {
                text = list_type_payment[i].label;
                break;
            }
        }
        return text;
    },
    async checkPermission(key_controller) {
        //var uid_user = store.getters.user;
        var uid_user = await AuthenticationApp.getUid();
        var users = await this.getUsersById(uid_user);
        // console.log("users",users)
        var list_role = [];
        //users.role == Constants.ROLE_ACCOUNT.SUPPER_ADMIN
        if (users.role == Constants.ROLE_ACCOUNT.SUPPER_ADMIN) {
            // if (account.type_admin == Constants.TYPE_ADMIN.SUPPER_ADMIN) {
            for (const [key, value] of Object.entries(Constants.ROLE)) {
                console.log(key);
                list_role.push(value);//full quyền
            }
            return list_role;
        }
        //type: {_eq: "${role}"},
        var query_controller = `query MyQuery {
          group_user(where: {uid: {_eq: "${uid_user}"}}) {
            group {
              group_roles(where: {role: { controller: {active: {_eq: true}, deleted: {_eq: false}, _key: {_eq: "${key_controller}"}}}}, order_by: {role: {controller: {ordinal: desc}}}) {
                role {
                  type
                  controller {
                    id
                    name
                    ordinal
                    parent_id
                    parent_tab
                    icon
                    _key
                    url
                    tab_service
                  }
                }
              }
            }
          }
        }
      `
        var resData = await createProvider().defaultClient.mutate({
            mutation: gql(query_controller)
        });
        console.log("resData.data.group_user",resData.data.group_user);
        var group_user = (!Common.isEmptyObject(resData.data.group_user)) ? resData.data.group_user : [];
        // var is_permission = false;
        console.log("group_user",group_user);
        var check_dulicate_role = {}
        if (group_user && group_user.length > 0) { //Vì 1 user có thể có nhiều quyền
            for (var g = 0; g < group_user.length; g++) {
                var group_user_obj = group_user[g];
                console.log("group_user_obj", group_user_obj)
                if (group_user_obj) {
                    if (group_user_obj.group) {
                        if (group_user_obj.group.group_roles) {
                            var controller_list = group_user_obj.group.group_roles;
                            for (var m = 0; m < controller_list.length; m++) {
                                if (controller_list[m].role) {
                                    if (controller_list[m].role.type) {
                                        if (Common.isEmpty(check_dulicate_role[controller_list[m].role.type])) {
                                            check_dulicate_role[controller_list[m].role.type] = 1
                                            list_role.push(controller_list[m].role.type);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        console.log('list_rolelist_role:',list_role)
        return list_role;
    },
    async getListControllerGroupUser(uid_user, offset = null, limit = null) {
        var query_controller = `query MyQuery ($offset: Int, $limit: Int) {
          group_user(where: {uid: {_eq: "${uid_user}"}}) {
            group {
              group_roles(where: {role: {type: {_eq: "view"}, controller: {_key: {_nin: ["add_user_to_role"]},active: {_eq: true}, deleted: {_eq: false}}}}, order_by: {role: {controller: {ordinal: desc}}}, offset: $offset, limit: $limit) {
                role {
                  type
                  controller {
                    id
                    name
                    ordinal
                    parent_id
                    parent_tab
                    icon
                    _key
                    url
                    tab_service
                    is_parent
                    parent_menu{
                    id
                    name
                    ordinal
                    parent_id
                    parent_tab
                    icon
                    _key
                    url
                    tab_service
                    is_parent
                    }
                  }
                }
              }
            }
          }
        }
      `
        var resData = await createProvider().defaultClient.mutate({
            mutation: gql(query_controller),
            variables: {
                offset: offset,
                limit: limit,
            }
        });
        var group_user = (!Common.isEmptyObject(resData.data.group_user)) ? resData.data.group_user : [];

        return group_user;
    },
    async geturlRedirectLogin() {
        //var account = store.getters.accountUser;
        //var uid_user = store.getters.user;
        var uid_user = await AuthenticationApp.getUid();
        /*var account = await this.getAccountByUidFirebase(uid_admin);
        console.log("accountaccountaccount:",account);
        var uid_user = (account) ? account.uid:null;*/
        var users = await this.getUsersById(uid_user);
        // console.log("accountaccountaccount:", users);
        var controller_list = [];
        var obj_cobntroller;
        if (users.role == Constants.ROLE_ACCOUNT.SUPPER_ADMIN) {
            var where_controller = {active: {_eq: true}, deleted: {_eq: false}, parent_tab: {_eq: true}};
            var order_by_controller = {ordinal: 'desc'};
            controller_list = await this.getListData('controller', where_controller, 0, 1, order_by_controller);
            obj_cobntroller = (controller_list.length > 0) ? controller_list[0] : null;
        } else {
            var group_user = await this.getListControllerGroupUser(uid_user, 0, 1);
            if (group_user && group_user.length > 0) {
                var group_user_obj = group_user[0];
                console.log("group_user_obj", group_user_obj)
                if (group_user_obj) {
                    if (group_user_obj.group) {
                        if (group_user_obj.group.group_roles) {
                            controller_list = group_user_obj.group.group_roles;
                            if (controller_list.length > 0) {
                                if (controller_list[0].role) {
                                    if (controller_list[0].role.controller) {
                                        obj_cobntroller = controller_list[0].role.controller;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        var arr_menu = [];
        var menu_education = [];
        /*var listService = await this.getService();
        if (listService && listService.length > 0){
            for (var i=0;i<listService.length;i++){
                if(listService[i].type == 5){
                    menu_education.push({
                        label: listService[i].name, icon: 'record-icon', to: '/admin/list-register/list/'+listService[i].id, id: 'list_register_'+listService[i].id
                    })
                }
                arr_menu.push(
                    {
                        label: listService[i].name, icon: 'record-icon', to: '/admin/list-service/list/'+listService[i].id, id: 'list_service_'+listService[i].id
                    }
                );
            }
        }*/
        console.log("obj_cobntrollerobj_cobntrollerobj_cobntroller:", obj_cobntroller)
        var url = null;
        if (obj_cobntroller) {
            if (!Common.isEmpty(obj_cobntroller.url)) {
                url = obj_cobntroller.url;
            } else {
                //nếu tồn tại menu child
                switch (obj_cobntroller.tab_service) {
                    case 0:
                        if (obj_cobntroller.rel_arr_controller_child && obj_cobntroller.rel_arr_controller_child.length > 0) {
                            var list_menu_child = obj_cobntroller.rel_arr_controller_child;
                            if (list_menu_child.length > 0) {
                                url = list_menu_child[0].url;
                            }
                        }
                        break;
                    case 1:
                        if (arr_menu.length > 0) {
                            url = arr_menu[0].to;
                        }
                        break;
                    case 2:
                        if (menu_education.length > 0) {
                            url = menu_education[0].to;
                        }
                        break;
                    default:
                    // code block
                }
            }

        }
        return url;

    },
    async uploadMultilpart(file, key_status, key_status_progress_bar) {
        //var calculateMD5 = await this.calculateMD5(file);
        var chunkSize = 1024 * 1024 * 6;//1 phận đoạn 6MB
        var chunks_lenght = Math.ceil(file.size / chunkSize);
        var dataRequest = {
            name_file: file.name,
            parts: chunks_lenght,
        };
        var dataState = {
            status: 1,//1:pending,2:error,3:thành công
        }
        const headers = {
            "Authorization": "Bearer " + (await AuthenticationApp.getToken()),
        };
        ApiRepository.post('/_api/upload-chunk/init_multipart_upload', dataRequest, {headers}).then(async (response) => {
            var dataSuccess = response.data;
            var dataObject = dataSuccess.data;
            var key_s3 = dataObject.key;
            var UploadId = dataObject.UploadId;
            var list_url = (dataObject.list_url) ? dataObject.list_url : [];
            console.log("list_url:", list_url);

            delete ApiRepository.defaults.headers.put['Content-Type']
            if (list_url.length > 0) {
                var listPart = [];
                var count_done = 0;
                var count_error = 0;
                for (var i = 0; i < list_url.length; i++) {
                    if (i % 5 == 0) {
                        await Common.sleep(3000);//deplay 3 giây
                    }
                    const start = i * chunkSize;
                    const end = (i + 1) * chunkSize;
                    const blob = (i < list_url.length) ? file.slice(start, end) : file.slice(start);
                    console.log("Uploading chunk:", i, "URL:", list_url[i].url);

                    this.sendChunkS3(list_url[i].url, blob, i).then(async (response) => {
                        if (response) {
                            count_done++;
                            if (count_done % 5 == 0) {
                                //setprocessbar
                                localStorage.setItem(key_status_progress_bar, ((count_done / list_url.length) * 100).toString());
                            }
                            console.log("responseS3:", response);
                            listPart.push(response);
                            if (count_done == list_url.length) {
                                var dataRequestComplete = {
                                    key_s3: key_s3,
                                    UploadId: UploadId,
                                    parts: listPart,
                                };
                                const headers = {
                                    "Authorization": "Bearer " + (await AuthenticationApp.getToken()),
                                };
                                ApiRepository.post('/_api/upload-chunk/complete_multi_upload', dataRequestComplete, {headers}).then(async (responseComplete) => {
                                    console.log("responseComplete", responseComplete)
                                    var dataComplete = responseComplete.data;
                                    var dataObjectComplete = dataComplete.data;
                                    /*console.log("dataObjectComplete:",(Cryptojs.MD5(file)).toString());
                                    console.log("dataObjectComplete:",(Cryptojs.MD5(dataObjectComplete.url)).toString());*/
                                    //this.downloadAndCreateHash( dataObjectComplete.url);
                                    dataState.status = 3;//Thành công
                                    dataState.data = dataObjectComplete;//Thành công
                                    localStorage.setItem(key_status, JSON.stringify(dataState));
                                })
                            }
                        } else {
                            count_error++;
                            //Nếu lỗi lần đầu thì update còn lần tiếp theo thì ko update
                            if (count_error == 1) {
                                dataState.status = 2;
                                localStorage.setItem(key_status, JSON.stringify(dataState));
                                //alert('Upload file thất bại! Vui lòng thử lại');
                                //Gọi APi hủy UploadId
                                this.abort_multipart_upload(key_s3, UploadId)
                            }

                        }
                    })
                        .catch((error) => {
                            console.log("error::", error)
                            count_error++;
                            //Nếu lỗi lần đầu thì update còn lần tiếp theo thì ko update
                            if (count_error == 1) {
                                //alert('Upload file thất bại! Vui lòng thử lại');
                                dataState.status = 2;
                                localStorage.setItem(key_status, JSON.stringify(dataState));
                                //Gọi APi hủy UploadId
                                this.abort_multipart_upload(key_s3, UploadId)
                            }
                        })
                }
            }
        })
            .catch((error) => {
                console.log(error)
                //alert('Upload file thất bại! Vui lòng thử lại');
                dataState.status = 2;
                localStorage.setItem(key_status, JSON.stringify(dataState));
            })
    },
    async sendChunkS3(url, blob, i) {
        try {
            var response = await ApiRepository.put(url, blob);
            console.log("(Cryptojs.SHA256(this.password.toString())).toString():", (Cryptojs.MD5(blob)).toString())
            console.log("response.headers.etag:", response.headers.etag)
            var hashChunk = await this.calculateMD5(blob);
            console.log("hashChunk:"+i+":"+hashChunk);
            console.log("response.headers.etag.trim():"+i+":"+response.headers.etag.trim());
            var Etag = response.headers.etag;
            //vi etag chứa thêm nhũng ký tự "" || nên dùng indexof
            if ((Etag.indexOf(hashChunk) > -1)){
                return {
                    ETag: response.headers.etag,
                    PartNumber: i + 1
                };
            }else {
                console.log("urlurl:"+url)
                console.log("SAI:"+i)
                return false;//ko toàn vẹn dữ liệu
            }

        } catch (err) {
            console.log("urlurl:"+url)
            console.log(err);
            return false;
        }
    },
    async abort_multipart_upload(key_s3, UploadId) { //HỦy một state upload
        try {
            var dataRequest = {
                key_s3: key_s3,
                UploadId: UploadId,
            };
            const headers = {
                "Authorization": "Bearer " + (await AuthenticationApp.getToken()),
            };
            await ApiRepository.post('/_api/upload-chunk/abort_multipart_upload', dataRequest, {headers});
        } catch (err) {
            console.log(err);
        }
    },
    fileHash(file, callback) {
        //Instantiate a reader
        var reader = new FileReader();

        //What to do when we gets data?
        reader.onload = function (e) {
            var hash = (Cryptojs.MD5(e.target.result)).toString();
            callback(hash);
        }
        reader.readAsBinaryString(file);
    }
    ,
    async downloadAndCreateHash(url) {
        fetch(url)
            .then(function (response) {
                return response.blob()
            })
            .then(blob => {
                return this.calculateMD5(blob)
            })
            .then(hash => {
                console.log(`Hash of the file is: ${hash}`)
            })
            .catch(err => {
                console.error(err)
            });

    },
    calculateMD5(blob) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(blob);
            reader.onloadend = function () {
                var wordArray = Cryptojs.lib.WordArray.create(reader.result),
                    hash = Cryptojs.MD5(wordArray).toString();
                resolve(hash)
            };
        })
    },
    async getListProperty(where_key) {
        var order_by = [{created_at : 'desc'}];
        var list = await this.getListData('property', where_key, null, null, order_by);
        var list_property = [];
        var list_map = {};
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            //var itemP = {id : e.id, name :e.name, code: e.code, address: e.address, part: e.part}
            list_map[e.id] = e;
            list_property.push(e);
        }
        return {
            list:list_property,
            list_map:list_map,
        }
    },
    async getListProperty1(where_key) {
        // var where_key_week = '';
        // var list_week = await this.getListData('property_week', where_key_week, null, null, order_by);
        // console.log(list_week)
        var order_by = [{created_at : 'desc'}];

        var list = await this.getListData('property', where_key, null, null, order_by);
        var list_property = [];
        var list_map = {};
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            //var itemP = {id : e.id, name :e.name, code: e.code, address: e.address, part: e.part}
            list_map[e.id] = e;
            list_property.push(e);
        }
        return {
            list:list_property,
            list_map:list_map,
        }
    },
    async getListPropertyType(where_key) {
        var order_by = [{created_at : 'desc'}];
        var list = await this.getListData('property_type', where_key, null, null, order_by);
        var list_cv = [];
        var map_data = {}
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            var item_data  = {id : e.id, name :e.name}
            list_cv.push(item_data);
            map_data[e.id] = item_data
        }
        return {
            list:list_cv,
            map_data:map_data,
        }
    },
    async getListCountry(where_key) {
        var order_by = [{created_at : 'desc'}];
        var list = await this.getListData('country', where_key, null, null, order_by);
        var list_cv = [];
        var map_list = {};
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_cv.push(e);
            map_list[e.id] = e;
        }
        return {
            list:list_cv,
            map_list:map_list,
        }
    },
    async getListState(where_key) {
        var order_by = [{created_at : 'desc'}];
        var list = await this.getListData('state', where_key, null, null, order_by);
        var list_cv = [];
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_cv.push({id : e.id, name :e.name});
        }
        return {
            list:list_cv
        }
    },
    async getListUser(where_key) {
        var order_by = [{created_at : 'desc'}];
        var list = await this.getListData('users', where_key, null, null, order_by);
        var list_cv = [];
        var list_map = {};
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            var itemP = {id : e.id, name :e.fullname};
            list_map[e.id] = itemP
            list_cv.push(itemP);
        }
        return {
            list:list_cv,
            list_map:list_map,
        }
    },
    async getListDocsCate(where_key) {
        var order_by = [{created_at : 'desc'}];
        var list = await this.getListData('document_category', where_key, null, null, order_by);
        var list_convert = [];
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_convert.push({id : e.id, name :e.name, code: e.code, address: e.address, part: e.part});
        }
        return {
            list:list_convert
        }
    },
    async getListCateReport(where_key) {
        var order_by = [{created_at : 'desc'}];
        var list = await this.getListData('report_category', where_key, null, null, order_by);
        var list_convert = [];
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_convert.push({id : e.id, name :e.name, code: e.code, address: e.address, part: e.part});
        }
        return {
            list:list_convert
        }
    },
    async getListWeekVisit(where_key) {
        var order_by = [{created_at : 'desc'}];
        var list = await this.getListData('visit', where_key, null, null, order_by);
        var list_convert = [];
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_convert.push({id : e.id, property_id :e.property_id, week_number: e.week_number, visit_start: e.visit_start, visit_end: e.visit_end});
        }
        return {
            list:list_convert
        }
    },
    // chi lay week co status checkin checkout
    async getListWeekVisitCheckinCheckOut(where_key) {
        // Define the filter for the status field
        where_key.status = { _in: ['ST006', 'ST007'] };

        // Create a query object for the 'where' condition
        var where = where_key;

        // Order results by 'created_at' in descending order
        var order_by = [{ created_at: 'desc' }];

        // Call 'getListData' to retrieve the list of visits
        var list = await this.getListData('visit', where, null, null, order_by);
        console.log('ssssssssssssssssss', list)
        // Prepare an empty array to store the converted results
        var list_convert = [];

        // Loop through the fetched list to transform data
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_convert.push({
                id: e.id,
                property_id: e.property_id,
                week_number: e.week_number,
                visit_start: e.visit_start,
                visit_end: e.visit_end
            });
        }

        // Return the final transformed list
        return {
            list: list_convert
        };
    },


    convertDateSystem(date_convert) {
        //Convert date chọn từ calenda sang giờ đang mạch
        return date_convert;
    },
    async getListLang(where_key) {
        var order_by = [{ordinal : 'desc'}];
        var list = await this.getListData('lang', where_key, null, null, order_by);
        var list_cv = list;
        //console.log("listlist111:",list.length);
        var map_list = {};
        for (let i = 0; i < list_cv.length; i++) {
            var itemData = list_cv[i];
            var id = itemData?.id ?? null;
            if (itemData?.iso_code=='da'){
                list_cv[i].iso_code = 'dk';
            }
            list_cv[i].content = '';
            list_cv[i].id_lang = list_cv[i].id;
            map_list[id] = itemData;
        }
        return {
            list:list_cv,
            map_list:map_list,
        }
    },
    async getLisRecommendationCategory(where_key) {
        var order_by = [{id : 'asc'}];
        var list = await this.getListData('recommendation_category', where_key, null, null, order_by);
        var list_cv = list;
        //console.log("listlist111:",list.length);
        // /*for (let i = 0; i < list.length; i++) {
        //     var e = list[i];
        //     list_cv.push({id : e.id, name :e.name,iso_code :e.iso_code});
        // //     recommendation_lang
        // }*/
        return {
            list:list_cv
        }
    },
    async getLisRecommendationLang(where_key) {
        var order_by = [{id : 'asc'}];
        var list = await this.getListData('recommendation_lang', where_key, null, null, order_by);
        var list_cv = list;
        console.log("listlist1112221:",list.length);
        // for (let i = 0; i < list.length; i++) {
        //     var e = list[i];
        //     list_cv.push({id : e.id, name :e.name,description :e.description});
        // //     recommendation_lang
        // }
        return {
            list:list_cv
        }
    },
    async getListProduct(where_key) {
        var order_by = [{created_at : 'desc'}];
        var list = await this.getListData('product', where_key, null, null, order_by);
        var list_id_product = [];
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_id_product.push(e.id)
        }
        var dataImage =  await this.getFileMapFile([Constants.TYPE_IMAGE.PRODUCT_AVATAR,Constants.TYPE_IMAGE.PRODUCT_NORMAL], list_id_product);
        var list_map_file = dataImage.list_map_file
        var list_convert = [];
        for (let j = 0; j < list.length; j++) {
            var itemData = list[j]
            var id = itemData?.id ?? null
            if (!Common.isEmpty(list_map_file[id])){
                var list_file_product = list_map_file[id];
                itemData["list_file_product"] = list_file_product
            }
            list_convert.push(itemData)
        }
        /* var list_convert = [];
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_convert.push({id : e.id, name :e.name, code: e.code, address: e.address, part: e.part});
        }*/
        return {
            list:list_convert
        }
    },
    async getListPeople(where_key) {
        var order_by = [{ordinal : 'asc'}];
        var list = await this.getListData('visit_people', where_key, null, null, order_by);
        var list_id_product = [];
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_id_product.push(e.id)
        }
        var dataImage =  await this.getFileMapFile([Constants.TYPE_IMAGE.IMG_PEOPLE], list_id_product);
        var list_map_file = dataImage.list_map_file
        var list_convert = [];
        for (let j = 0; j < list.length; j++) {
            var itemData = list[j]
            var id = itemData?.id ?? null
            if (!Common.isEmpty(list_map_file[id])){
                var list_file_product = list_map_file[id];
                itemData["list_file"] = list_file_product
            }
            list_convert.push(itemData)
        }
        return {
            list:list_convert
        }
    },
    async getListUserPropertyPart(where_key) {
        var order_by = [{created_at : 'desc'}];
        var dataList = await this.getListDataFullData('user_property_part', where_key, null, null, order_by);
        var list = dataList?.data?.property ?? [];
        /*var list_convert = [];
        var stt = 0;
        for (let j = 0; j < list.length; j++) {
            stt++;
            var itemData = list[j]
            var name_txt = itemData?.name ?? null
            var part = itemData?.part ?? [];
            var part_txt = "";
            for (let k = 0; k < part.length; k++) {
                var itemPart = part[k];
                if (!Common.isEmpty(part_txt)){
                    part_txt +=' ,';
                }
                part_txt +=itemPart?.order_booking ?? '';
            }
            itemData.part_txt = part_txt;
            itemData.name_txt = name_txt;
            itemData.stt = stt;
            list_convert.push(itemData)
        }*/
        return {
            list:list
        }
    },
    getFilterPlaceHolder(col) {
        if (col.filter.placeHolder) {
            return col.filter.placeHolder;
        } else if (col.filter.type == "select") {
            return `Choose`;
        } else if (col.filter.type == "input") {
            return `Input`;
        } else {
            return `Filter`;
        }
    },
}
