const recommendationRouter = [
  {
      path: "list-payment-history",
      name: "list",
      component: () => import("@/views/admin/payment_history"),
      default: true,
      meta: {
          model: "visit_order_payment",
          // requiresAuth: true,
      },
  },
  {
      path: "list-payment-history/edit/:id",
      name: "edit",
      component: () => import("@/views/admin/payment_history/function"),
      default: true,
      meta: {
          model: "visit_order_payment",
          // requiresAuth: true,
      },
  },
];
export default recommendationRouter;