import BaseModel from '../core/BaseModel'
import  UserModel from "@/models/UserModel";
import PropertyModel from "@/models/PropertyModel";
export default class RecommendationUsedModel extends BaseModel {

    constructor() {
        super({
                name: "recommendation_use",
                tableName: 'recommendation_use',
                // label: "Recommendation Used",
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                recommendation_id: {
                    name: 'recommendation_id',
                    type: "Uuid",
                    label: 'recommendation_id',
                    primaryKey: true,
                    display: false
                },
                uid_key: {
                    name: "uid_key",
                    label: "User",
                    type: "Computed",
                    custom: 'SelectionText',
                    models: new UserModel(),
                    filter: {type: 'input', match: 'contains'},
                    class:"left",
                    computed: (data) => {
                        data.uid_key = '';
                    },
                },



                property_id_key: {
                    name: 'property_id_key',
                    type: "Uuid",
                    label: 'Property ID',
                    custom: 'SelectionText',
                    models: new PropertyModel(),
                    filter: {type: 'input', match: 'contains'},

                    computed: (data) => {
                        data.property_id_key = '';
                    },
                },
                discount: {
                    name: 'discount',
                    type: "String",
                    label: 'Discount',
                    display : false

                },

                discount_value: {
                    name: 'discount_value',
                    type: "Number",
                    label: 'Discount',
                    class:"right",
                },
                updated_at : {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Updated date',
                    filter: { type: 'DateRange', match: 'contains'}
                },
                // fullname_key: {
                //     name: "fullname_key",
                //     label: "Updated by",
                //     type: "Computed",
                //     computed: (data) => {
                //         data.fullname_key = '';
                //     },
                // },
                detail_get: {
                    refs:  'obj_user { id fullname } obj_property {id code}',
                    display: false
                },
            })
    }

}