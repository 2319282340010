import BaseModel from '../core/BaseModel'
import Constants from '@/core/Constants'
//import gql from 'graphql-tag'
export default class RequestOrderModel extends BaseModel {

    constructor() {
        super({ 
                name: "visit_need_to_request_item",
                tableName: 'visit_need_to_request_item',
                label: "Request for service", 
                default_order: { key: 'updated_at', direction: 'desc' } }, 
            {
            stt: {
                name: "stt",
                label: "No.",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },
            order_item: {
                refs: "order_item {id order{id code payment_method}}",
                display: false
            },
            request: {
                refs: "request {id code property {id code name} visit{id arrival}}",
                display: false
            },
            // products: {
            //     refs: "products {id price qty}",
            //     display: false
            // },
            price: {
                name: "price",
                display: false
            },
            service: {
                refs: "obj_service {id name}",
                display: false
            },
            created: {
                refs: "created {id fullname}",
                display: false
            },
            update_user: {
                refs: "update_user {id fullname}",
                display: false
            },
            assign: {
                refs: "assign {id fullname}",
                display: false
            },
            request_code: {
                name: 'request_code',
                type: "String",
                label: 'Request ID',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.request_code = '';
                },
            },
            order_code: {
                name: 'order_code',
                type: "CustomUrl",
                label: 'Order ID',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.order_code = '';
                },
            },
            service_name: {
                name: 'service_name',
                type: "CustomUrl",
                label: 'Service',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.service_name = '';
                },
            },
            booked: {
                name: 'booked',
                type: "String",
                label: 'Booked by',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.booked = '';
                },
            },
            property_code: {
                name: 'property_code',
                type: "CustomUrl",
                label: 'Property ID',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.property_code = '';
                },
            },
            property_name: {
                name: 'property_name',
                type: "CustomUrl",
                label: 'Property',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.property_name = '';
                },
            },
            total: {
                name: 'total',
                type: "String",
                label: 'Total',
                class: 'right',
                // filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.total = '';
                },
            },
            payment_method: {
                name: 'payment_method',
                type: "Selection",
                custom: "SelectionText",
                label: 'Payment method',
                options: [
                    {value: 1, label: 'Online'}, {value: 2, label: 'Cash'}
                ],
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                computed: (data) => {
                    data.payment_method = '';
                }
            },
            arrival: { 
                name: 'arrival', 
                type: "Datetime",
                label: 'Arrival Date',
                filter: { type: 'DateRange', match: 'contains'},
                computed: (data) => {
                    data.arrival = '';
                },
            },
            time_to_finish: { 
                name: 'time_to_finish', 
                type: "Datetime",
                label: 'Time to be finish',
                filter: { type: 'DateRange', match: 'contains'}
            },
            assign_name : { 
                name: 'assign_name', 
                type: "String",
                label: 'Assign',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.assign_name = '';
                },
            },
            payment_status: { 
                name: 'payment_status', 
                type: "Selection",
                label: 'Request status',
                options: Constants.getConstans().REQUEST_STATUS,
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' }
            },
            created_at : { 
                name: 'updated_at', 
                type: "Datetime",
                label: 'Created date',
                filter: { type: 'DateRange', match: 'contains'},
            },
            updated_name : { 
                name: 'updated_name', 
                type: "String",
                label: 'Updated by',
                computed: (data) => {
                    data.updated_name = '';
                },
            },
            updated_at : { 
                name: 'updated_at', 
                type: "Datetime",
                label: 'Updated date'
            }
        },)
    }

}