import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase'
import randomize from 'randomatic'
import ApiRepository from "../core/ApiRepository";
import AuthenticationApp from "../core/AuthenticationApp";
/*import GetConstants from '../core/Constants';
var Constants = GetConstants.getConstans();*/
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    status: null,
    error: null,
    userProfile: {},
    fullname: null,
    isLoggedIn: false,
    isHomePage: false,
    token: null,
    token_refesh: null,
    sentRequest: null,
    isLoading: false,
    role: null,
    unsaveChange: null,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setFullname(state, payload) {
      state.fullname = payload
    },
    removeUser(state) {
      state.user = null
    },
    setStatus(state, payload) {
      state.status = payload
    },
    setError(state, payload) {
      state.error = payload
    },
    setIsLoggedIn(state, payload) {
      state.isLoggedIn = payload
    },
    setIsHomePage(state, payload) {
      state.isHomePage = payload
    },
    setToken(state, payload) {
      state.token = payload
    },
    setTokenRefesh(state, payload) {
      state.token_refesh = payload
    },
    setSentRequest(state, payload) {
      state.sentRequest = payload
    },
    setRole(state, payload) {
      state.role = payload
    },
    setLoading(state, payload) {
      state.isLoading = payload
      setTimeout(function () {
        state.isLoading = false
      }, 5000)
    },
    clearUnsavedChange(state) {
      state.unsaveChange = null
    }
  },
  actions: {
    deleteUserAction({commit}, payload) {
      console.log('deleteUserAction', payload)
      return new Promise((resolve, reject) => {
        firebase.auth().deleteUser(payload.uid)
        .then((response) => {
          resolve(response)
          resolve('OK')
        })
        .catch((error) => {
          commit('setError', error.message)
          reject(error)
        });
      })
    },
    resetPasswordAction({commit}, payload) {
      return new Promise((resolve, reject) => {
        var code = randomize('0', 6);
        firebase.auth().sendPasswordResetEmail(payload.email, code)
        .then((response) => {
          resolve(response)
          resolve('OK')
        })
        .catch((error) => {
          commit('setError', error.message)
          reject(error)
        });
      })
    },
    signUpAction({commit}, payload) {
      return new Promise((resolve, reject) => {
        firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
        .then(async (response) => {
          await response.user.sendEmailVerification();
          resolve(response)
          resolve('OK')
        })
        .catch((error) => {
          commit('setError', error.message)
          reject(error)
        })
      })
    },
    signInAction({ commit }, payload) {
      return new Promise((resolve, reject) => {
        ApiRepository.post('/_api/users/login-admin',payload).then(async (response) => {
          var dataSuccess = response.data;
          var dataUser = dataSuccess.data;
          console.log("dataUserdataUserdataUser:",dataUser)
          commit('setUser', dataUser.id)
          commit('setStatus', 'success')
          commit('setError', null)
          commit('setToken', dataUser.token);
          commit('setTokenRefesh', dataUser.token_refesh);
          var dataState = {
            uid:dataUser.id,
            token:dataUser.token,
            token_refesh:dataUser.token_refesh,
            role : dataUser.role,
            list_property_id: Array.isArray(dataUser.list_property_id) ? dataUser.list_property_id : [dataUser.list_property_id],
          }
          AuthenticationApp.setStateLogin(dataState);
          resolve(response)
          resolve('OK')
        })
            .catch((error) => {
              commit('setStatus', 'failure')
              commit('setError', error.message)
              reject(error)
            })

        /*firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
          .then(async (response) => {
            commit('setUser', response.user.uid)
            commit('setStatus', 'success')
            commit('setError', null)
            var token = await response.user.getIdToken(true)
            commit('setToken', token)
            resolve(response)
            resolve('OK')
          })
          .catch((error) => {
            commit('setStatus', 'failure')
            commit('setError', error.message)
            reject(error)
          })*/
      })
    },
    signOutAction({ commit }) {
      commit('setUser', null)
      commit('setStatus', 'success')
      commit('setError', null)
      commit('setToken', null)
    },
    /*signOutAction({ commit }) {
      firebase.auth().signOut()
        .then(() => {
          // console.log(response)
          commit('setUser', null)
          commit('setStatus', 'success')
          commit('setError', null)
          commit('setToken', null)
        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
        })
    },*/
    CreateQR({ commit },payload) {
      return new Promise((resolve, reject) => {
        ApiRepository.post('/_api/users/create_qr_ipro_code_restore',payload).then(async (response) => {
          commit('setError', null)
          resolve(response)
          resolve('OK')
        })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },

  getters: {
    status(state) {
      return state.status
    },
    user(state) {
      return state.user
    },
    fullname(state) {
      return state.fullname
    },
    isLoggedIn(state) {
      return state.isLoggedIn
    },
    error(state) {
      return state.error
    },
    isHomePage(state) {
      return state.isHomePage
    },
    token(state) {
      return state.token
    },
    tokenRefesh(state) {
      return state.token_refesh
    },
    sentRequest(state) {
      return state.sentRequest
    },
    role(state) {
      return state.role
    },
    isLoading(state) {
      return state.isLoading
    },
    unsaveChange(state) {
      return state.unsaveChange
    }
  }
})
