const report_categoryRouter = [
  {
      path: "list-report_category",
      name: "list",
      component: () => import("@/views/admin/report_category"),
      default: true,
      meta: {
          model: "report_category",
          // requiresAuth: true,
      },
  },
  {
      path: "list-report_category/add",
      name: "add",
      component: () => import("@/views/admin/report_category/function"),
      default: true,
      meta: {
          model: "report_category",
          // requiresAuth: true,
      },
  },
  {

      path: "list-report_category/edit/:id",
      name: "edit",
      component: () => import("@/views/admin/report_category/function"),
      default: true,
      meta: {
          model: "report_category",
          // requiresAuth: true,
      },
  },
];
export default report_categoryRouter;