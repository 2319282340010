import BaseModel from '../core/BaseModel'
import PropertyModel from "@/models/PropertyModel";
import UserModel from '@/models/UserModel';
import ReportCategoryModel from "@/models/ReportCategoryModel";
export default class ReportManagementModel extends BaseModel {

    constructor() {
        super({
                name: "visit_report",
                tableName: 'visit_report',
                label: "Report mangement",
                // display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                visit_id: {
                    name: 'visit_id',
                    type: "Uuid",
                    display: false,

                },
                content: {
                    name: 'content',
                    type: "text",
                    display: false
                },

                code: {
                    name:'code',
                    type: "String",
                    label:'Report ID',
                    filter: {type: 'input', match: 'contains'},
                    class : 'left'
                },
                property_id_key: {
                    name: 'property_id_key',
                    type: "CustomUrl",
                    label: 'Property ID',
                    custom: 'SelectionText',
                    models: new PropertyModel(),
                    filter: {type: 'input', match: 'contains'},
                    class:"left",
                    computed: (data) => {
                        data.property_id_key = '';
                    },
                },
                property_name_key: {
                    name: 'property_name_key',
                    type: "CustomUrl",
                    label: 'Property',
                    custom: 'SelectionText',
                    models: new PropertyModel(),
                    filter: {type: 'input', match: 'contains'},
                    class:"left",
                    width :20,
                    computed: (data) => {
                        data.property_name_key = '';
                    },
                },

                // category_id: {
                //     name:'category_id',
                //     label:'Category',
                //     type: "Selection",
                //     custom: 'SelectionText',
                //     models: new RecommendationCategoryModel(),
                //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                //     class:"center",
                //     computed: (data) => {
                //         data.category_id = '';
                //     },
                // },
                category_id: {
                    name:'category_id',
                    label:'Category',
                    type: "CustomUrl",
                    custom: 'SelectionText',
                    models: new ReportCategoryModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    class:"left",
                    computed: (data) => {
                        data.category_id = '';
                    },

                },
                // property_id_key: {
                //     name: 'property_id_key',
                //     type: "String",
                //     label: 'Property ID',
                //     custom: 'SelectionText',
                //     models: new PropertyModel(),
                //     filter: {type: 'input', match: 'contains'},
                //     class:"left",
                //     computed: (data) => {
                //         data.property_id_key = '';
                //     },
                // },
                created_by_key: {
                    name: "created_by_key",
                    label: "Created by",
                    type: "String",
                    custom: 'SelectionText',
                    models: new UserModel(),
                    filter: {type: 'input', match: 'contains'},
                    class:"left",
                    width : 8,
                    computed: (data) => {
                        data.created_by_key = '';
                        },
                },

                status: {
                    name: "status",
                    label: "Status",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 'ST001', label: 'Open',style: 'color:  #E73725;'},
                        {value: 'ST002', label: 'Closed',style: 'color:  #2AA847;'},
                        {value: 'ST003', label: 'Cancelled',style: 'color:  #1D3118;'},
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"left",
                },
                updated_by_key: {
                    name: "updated_by_key",
                    label: "Updated by",
                    type: "Computed",
                    computed: (data) => {
                        data.updated_by_key = '';
                    },
                },
                updated_at : {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Updated date',
                },
                created_at : {
                    name: 'created_at',
                    type: "Datetime",
                    display: false

                },
                detail_get: {
                    refs:  'property {id code name} report_category {id name } obj_updated_by {id fullname} user {id fullname} visit {id uid week_number visit_start visit_end }',
                    display: false
                },

            })
    }

}