import BaseModel from '../core/BaseModel'
//import UserModel from '@/models/UserModel'
export default class FullFillProductModel extends BaseModel {

    constructor() {
        super({
                name: "full_fill_product",
                tableName: 'product',
                label: "full_fill_product",
                //display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                avatar: {
                    name: "avatar",
                    label: "Image",
                    custom: 'image',
                    type: "Computed",
                    computed: (data, index) => {
                        data.avatar = index + 1;
                    },
                    //display: false,
                },
                name: {
                    name: 'name',
                    type: "String",
                    label: 'Product name',
                    width: 15,
                    //filter: {type: 'input', match: 'contains'},
                },
                description: {
                    name: "description",
                    label: "Description",
                    type: "Computed",
                    computed: (data) => {
                        //console.log("datadata:",data)
                        data.description = '';
                    },
                },
                price: {
                    name: "price",
                    label: "Price",
                    type: "Computed",
                    computed: (data) => {
                        data.price =  data.price_key;
                    },
                },
                ordinal: {
                    name: 'ordinal',
                    type: "String",
                    label: 'Order'
                },
                active: {
                    name: "active",
                    label: "Active",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 'true', label: 'Yes', style: 'color:  #000000;'},
                        {value: 'false', label: 'No',style: 'color:  #000000;'}
                    ],
                   /* filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },*/
                    class:"center ",
                },
            })
    }

}