import BaseModel from '../core/BaseModel'
export default class PropertyTypeModel extends BaseModel {

    constructor() {
        super({
                name: "property_type",
                tableName: 'property_type',
                label: "property_type",
            display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                name: {
                    name: 'name',
                    type: "String",
                    label: 'Property name'
                },
            })
    }

}